import enumOverrides from '../../enum-overrides';

export default class FormFieldsController {
    constructor($scope, $sce) {
        this.scope = $scope;
        this.sce = $sce;
        this.lodash = _;

        this._resetState();
        this._initWatchers();
    }

    /**
     *
     * @param fieldPath
     */
    updateDataValue(fieldPath) {
        this._triggerUpdatedDataEvent();
    }

    trustAsHtml(content) {
        return this.sce.trustAsHtml(content);
    }

    /**
     *
     * @private
     */
    _triggerUpdatedDataEvent() {
        this.onDataChange({
            data: this._deFlatFormData()
        });
    }

    /**
     *
     * @private
     */
    _deFlatFormData() {
        const out = {};

        _.each(this.state.data, (value, key) => {
            const fieldInformation = _.first(_.filter(this.state.fields, (field) => {
                return field.path === key;
            }));

            if (fieldInformation) {
                switch (fieldInformation.dataType) {
                    case 'bool':
                        value = !!value;
                        break;
                    case 'date':
                        if (value) {
                            const dateObject = moment(value, 'DD.MM.YYYY');
                            value = dateObject.format('YYYY-MM-DD');
                        } else {
                            value = null;
                        }
                        break;
                    case 'datetime':
                        if (value) {
                            const dateObject = moment(value, 'DD.MM.YYYY HH:mm');
                            value = dateObject.toISOString();
                        } else {
                            value = null;
                        }
                        break;
                }
            }
            _.set(out, key, value);
        });

        return out;
    }

    /**
     * Init necessary watchers on the scope
     * @private
     */
    _initWatchers() {
        this.scope.$watch(() => {
            return this.formData;
        }, () => {
            if (this.formData) {
                this._prepareDataFromFormData();
            }
        });

        this.scope.$watch(() => {
            return this.formConfig;
        }, () => {
            if (this.formConfig) {
                this._prepareFormFields();
            }
        });
    }

    /**
     *
     * @private
     */
    _prepareDataFromFormData() {
        const newData = {};
        if (_.isEmpty(this.state.fields)) {
            return;
        }

        _.each(this.formData, (groupValues, groupKey) => {
            if ((['contact', 'rsvp']).indexOf(groupKey) === -1) {
                newData[groupKey] = groupValues;
                return;
            }

            _.each(groupValues, (value, fieldKey) => {
                const fieldPath = groupKey + '.' + fieldKey,
                    fieldInformation = _.first(_.filter(this.state.fields, (field) => {
                        return field.path === fieldPath;
                    }));

                if (fieldInformation) {
                    switch (fieldInformation.dataType) {
                        case 'string':
                            if (fieldInformation.enum) {
                                if (_.isUndefined(_.get(fieldInformation.enum, value))) {
                                    value = null;
                                }
                            }
                            break;
                        case 'bool':
                            value = !!value;
                            break;
                        case 'date':
                            if (value) {
                                const dateObject = moment(value, 'YYYY-MM-DD');
                                value = dateObject.format('DD.MM.YYYY');
                            } else {
                                value = null;
                            }
                            break;
                        case 'datetime':
                            if (value) {
                                const dateObject = moment(value);
                                value = dateObject.format('DD.MM.YYYY HH:mm');
                            } else {
                                value = null;
                            }
                            break;
                    }
                }

                // if(fieldInformation)
                newData[fieldPath] = value;
            });
        });

        this._setState({
            ...this.state,
            data: newData
        });
    }

    /**
     * Prepares the given config to become an valid option for use on the form
     * @private
     */
    _prepareFormFields() {
        let newFields = [];

        _.each(this.formConfig, (fieldGroup, fieldGroupKey) => {
            _.each(fieldGroup, (fieldInformation, fieldKey) => {
                const fieldPath = fieldGroupKey + '.' + fieldKey,
                    newField = {
                        path: fieldPath,
                        label: fieldInformation.label,
                        order: fieldInformation.order,
                        required: fieldInformation.required,
                        locked: fieldInformation.locked,
                        dataType: fieldInformation.type,
                        type: fieldInformation.type
                    };

                if (!_.isUndefined(fieldInformation.max)) {
                    newField.max = fieldInformation.max;
                }

                if (!_.isUndefined(fieldInformation.multiline)) {
                    newField.multiline = fieldInformation.multiline;
                }

                if (fieldInformation.enum) {
                    newField.type = 'select';
                    const override = _.get(enumOverrides, fieldPath);
                    if (!_.isUndefined(override)) {
                        newField.enum = override;
                    } else {
                        newField.enum = fieldInformation.enum;
                    }
                }

                newFields.push(newField);
            });
        });

        this._setState({
            ...this.state,
            fields: newFields
        });

        if (Object.keys(this.state.data).length === 0) {
            this._prepareDataFromFormData();
        }
    }

    /**
     *
     * @param state
     * @private
     */
    _setState(state) {
        this.state = state;
        this.scope.$applyAsync();
    }

    /**
     *
     * @private
     */
    _resetState() {
        this._setState({
            fields: [],
            data: {}
        });
    }
}

FormFieldsController.$inject = [
    '$scope',
    '$sce'
];
